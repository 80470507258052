// Auth Routes

export default {
  LOGIN_URL: 'login/',
  SIGNIN_URL: 'authenticate/',
  LOGOUT_URL: 'logout/',
  GENERATE_TOKEN: 'generate-token/',
  RESET_PASSWORD: 'reset-password/',
  FORGOT_USERNAME: 'forgot-username/',
  LOGGED_IN_USER: 'logged-in-user/',
  SET_PASSWORD: 'set-password/',
  SWITCH_COMPANY: 'switch-company/',
  SET_PROPERTIES: 'companies/:company_id/set-properties/',
  REGISTER: 'register/',
  GLOBAL_SETTINGS: 'companies/:company_id/global-settings/',
  HELP_JUICE_URL: 'help-juice/',
}
